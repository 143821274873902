import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6b43b0ee = () => interopDefault(import('../src/pages/library.vue' /* webpackChunkName: "" */))
const _5ceffd00 = () => interopDefault(import('../src/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _7989e540 = () => interopDefault(import('../src/pages/account.vue' /* webpackChunkName: "pages/account" */))
const _38d56ba6 = () => interopDefault(import('../src/pages/affiliates/index.vue' /* webpackChunkName: "pages/affiliates/index" */))
const _3c9aef5f = () => interopDefault(import('../src/pages/assessment.vue' /* webpackChunkName: "pages/assessment" */))
const _7499e66c = () => interopDefault(import('../src/pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _b5120cb0 = () => interopDefault(import('../src/pages/delete-account.vue' /* webpackChunkName: "pages/delete-account" */))
const _e0e57c22 = () => interopDefault(import('../src/pages/extracts.vue' /* webpackChunkName: "pages/extracts" */))
const _aae3916e = () => interopDefault(import('../src/pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _d98284c6 = () => interopDefault(import('../src/pages/forParents.vue' /* webpackChunkName: "pages/forParents" */))
const _624f9134 = () => interopDefault(import('../src/pages/forStudents.vue' /* webpackChunkName: "pages/forStudents" */))
const _5ebb37ed = () => interopDefault(import('../src/pages/forTeachers.vue' /* webpackChunkName: "pages/forTeachers" */))
const _2f839c62 = () => interopDefault(import('../src/pages/google-analytics.vue' /* webpackChunkName: "pages/google-analytics" */))
const _16dcd193 = () => interopDefault(import('../src/pages/helpCenter.vue' /* webpackChunkName: "pages/helpCenter" */))
const _8a310148 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _6ad23716 = () => interopDefault(import('../src/pages/manageAuthors.vue' /* webpackChunkName: "pages/manageAuthors" */))
const _53e93769 = () => interopDefault(import('../src/pages/offline-fallback.vue' /* webpackChunkName: "pages/offline-fallback" */))
const _b24ecb90 = () => interopDefault(import('../src/pages/onboarding.vue' /* webpackChunkName: "pages/onboarding" */))
const _270ca30e = () => interopDefault(import('../src/pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _fcd29e18 = () => interopDefault(import('../src/pages/savedForOffline.vue' /* webpackChunkName: "pages/savedForOffline" */))
const _3b0af476 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _b5e4fef8 = () => interopDefault(import('../src/pages/state.vue' /* webpackChunkName: "pages/state" */))
const _1d2cbc06 = () => interopDefault(import('../src/pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _fcbf673e = () => interopDefault(import('../src/pages/thank_you_for_registration.vue' /* webpackChunkName: "pages/thank_you_for_registration" */))
const _85c34c9a = () => interopDefault(import('../src/pages/verify_email.vue' /* webpackChunkName: "pages/verify_email" */))
const _2634afba = () => interopDefault(import('../src/pages/category/favorites.vue' /* webpackChunkName: "pages/category/favorites" */))
const _3b86cdf6 = () => interopDefault(import('../src/pages/dashboard/reading-group.vue' /* webpackChunkName: "pages/dashboard/reading-group" */))
const _13fbb00e = () => interopDefault(import('../src/pages/subscription/cards.vue' /* webpackChunkName: "pages/subscription/cards" */))
const _416513ff = () => interopDefault(import('../src/pages/subscription/history.vue' /* webpackChunkName: "pages/subscription/history" */))
const _0062a236 = () => interopDefault(import('../src/pages/affiliates/_affiliateId.vue' /* webpackChunkName: "pages/affiliates/_affiliateId" */))
const _53f2d3de = () => interopDefault(import('../src/pages/author/_authorSlug/index.vue' /* webpackChunkName: "pages/author/_authorSlug/index" */))
const _0f9729b6 = () => interopDefault(import('../src/pages/category/_name.vue' /* webpackChunkName: "pages/category/_name" */))
const _71d15166 = () => interopDefault(import('../src/pages/collection/_id.vue' /* webpackChunkName: "pages/collection/_id" */))
const _04744906 = () => interopDefault(import('../src/pages/developlibraryset/_setId.vue' /* webpackChunkName: "pages/developlibraryset/_setId" */))
const _667c7bb4 = () => interopDefault(import('../src/pages/developstudycourse/_syllabusId.vue' /* webpackChunkName: "pages/developstudycourse/_syllabusId" */))
const _c4ae5dde = () => interopDefault(import('../src/pages/genre/_name.vue' /* webpackChunkName: "pages/genre/_name" */))
const _094b8463 = () => interopDefault(import('../src/pages/manageauthor/_authorId.vue' /* webpackChunkName: "pages/manageauthor/_authorId" */))
const _5f029d07 = () => interopDefault(import('../src/pages/author/_authorSlug/bio.vue' /* webpackChunkName: "pages/author/_authorSlug/bio" */))
const _2510f415 = () => interopDefault(import('../src/pages/author/_authorSlug/faq.vue' /* webpackChunkName: "pages/author/_authorSlug/faq" */))
const _b6242ce2 = () => interopDefault(import('../src/components/pages/LoginPage/LoginPage.vue' /* webpackChunkName: "" */))
const _20ed8a06 = () => interopDefault(import('../src/pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bloggerAuth",
    redirect: "about",
    name: "bloggerAuth"
  }, {
    path: "/library_(\\w{2})",
    component: _6b43b0ee,
    name: "library_language"
  }, {
    path: "/about/",
    component: _5ceffd00,
    pathToRegexpOptions: {"strict":true},
    name: "about"
  }, {
    path: "/account/",
    component: _7989e540,
    pathToRegexpOptions: {"strict":true},
    name: "account"
  }, {
    path: "/affiliates/",
    component: _38d56ba6,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates"
  }, {
    path: "/assessment/",
    component: _3c9aef5f,
    pathToRegexpOptions: {"strict":true},
    name: "assessment"
  }, {
    path: "/dashboard/",
    component: _7499e66c,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard"
  }, {
    path: "/delete-account/",
    component: _b5120cb0,
    pathToRegexpOptions: {"strict":true},
    name: "delete-account"
  }, {
    path: "/extracts/",
    component: _e0e57c22,
    pathToRegexpOptions: {"strict":true},
    name: "extracts"
  }, {
    path: "/faq/",
    component: _aae3916e,
    pathToRegexpOptions: {"strict":true},
    name: "faq"
  }, {
    path: "/forParents/",
    component: _d98284c6,
    pathToRegexpOptions: {"strict":true},
    name: "forParents"
  }, {
    path: "/forStudents/",
    component: _624f9134,
    pathToRegexpOptions: {"strict":true},
    name: "forStudents"
  }, {
    path: "/forTeachers/",
    component: _5ebb37ed,
    pathToRegexpOptions: {"strict":true},
    name: "forTeachers"
  }, {
    path: "/google-analytics/",
    component: _2f839c62,
    pathToRegexpOptions: {"strict":true},
    name: "google-analytics"
  }, {
    path: "/helpCenter/",
    component: _16dcd193,
    pathToRegexpOptions: {"strict":true},
    name: "helpCenter"
  }, {
    path: "/library/",
    component: _6b43b0ee,
    pathToRegexpOptions: {"strict":true},
    name: "library"
  }, {
    path: "/login/",
    component: _8a310148,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/manageAuthors/",
    component: _6ad23716,
    pathToRegexpOptions: {"strict":true},
    name: "manageAuthors"
  }, {
    path: "/offline-fallback/",
    component: _53e93769,
    pathToRegexpOptions: {"strict":true},
    name: "offline-fallback"
  }, {
    path: "/onboarding/",
    component: _b24ecb90,
    pathToRegexpOptions: {"strict":true},
    name: "onboarding"
  }, {
    path: "/pricing/",
    component: _270ca30e,
    pathToRegexpOptions: {"strict":true},
    name: "pricing"
  }, {
    path: "/savedForOffline/",
    component: _fcd29e18,
    pathToRegexpOptions: {"strict":true},
    name: "savedForOffline"
  }, {
    path: "/search/",
    component: _3b0af476,
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/state/",
    component: _b5e4fef8,
    pathToRegexpOptions: {"strict":true},
    name: "state"
  }, {
    path: "/subscription/",
    component: _1d2cbc06,
    pathToRegexpOptions: {"strict":true},
    name: "subscription"
  }, {
    path: "/thank_you_for_registration/",
    component: _fcbf673e,
    pathToRegexpOptions: {"strict":true},
    name: "thank_you_for_registration"
  }, {
    path: "/verify_email/",
    component: _85c34c9a,
    pathToRegexpOptions: {"strict":true},
    name: "verify_email"
  }, {
    path: "/category/favorites/",
    component: _2634afba,
    pathToRegexpOptions: {"strict":true},
    name: "category-favorites"
  }, {
    path: "/dashboard/reading-group/",
    component: _3b86cdf6,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-reading-group"
  }, {
    path: "/subscription/cards/",
    component: _13fbb00e,
    pathToRegexpOptions: {"strict":true},
    name: "subscription-cards"
  }, {
    path: "/subscription/history/",
    component: _416513ff,
    pathToRegexpOptions: {"strict":true},
    name: "subscription-history"
  }, {
    path: "/",
    component: _5ceffd00,
    name: "about"
  }, {
    path: "/affiliates/:affiliateId/",
    component: _0062a236,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates-affiliateId"
  }, {
    path: "/author/:authorSlug/",
    component: _53f2d3de,
    pathToRegexpOptions: {"strict":true},
    name: "author-authorSlug"
  }, {
    path: "/category/:name?/",
    component: _0f9729b6,
    pathToRegexpOptions: {"strict":true},
    name: "category-name"
  }, {
    path: "/collection/:id?/",
    component: _71d15166,
    pathToRegexpOptions: {"strict":true},
    name: "collection-id"
  }, {
    path: "/developlibraryset/:setId?/",
    component: _04744906,
    pathToRegexpOptions: {"strict":true},
    name: "developlibraryset-setId"
  }, {
    path: "/developstudycourse/:syllabusId?/",
    component: _667c7bb4,
    pathToRegexpOptions: {"strict":true},
    name: "developstudycourse-syllabusId"
  }, {
    path: "/genre/:name?/",
    component: _c4ae5dde,
    pathToRegexpOptions: {"strict":true},
    name: "genre-name"
  }, {
    path: "/manageauthor/:authorId?/",
    component: _094b8463,
    pathToRegexpOptions: {"strict":true},
    name: "manageauthor-authorId"
  }, {
    path: "/author/:authorSlug?/bio/",
    component: _5f029d07,
    pathToRegexpOptions: {"strict":true},
    name: "author-authorSlug-bio"
  }, {
    path: "/author/:authorSlug?/faq/",
    component: _2510f415,
    pathToRegexpOptions: {"strict":true},
    name: "author-authorSlug-faq"
  }, {
    path: "/access_token*",
    component: _b6242ce2,
    name: "access_token"
  }, {
    path: "/state*",
    component: _b6242ce2
  }, {
    path: "/:slug?/",
    component: _20ed8a06,
    pathToRegexpOptions: {"strict":true},
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
